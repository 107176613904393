import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentApi, FolderApi } from '../api';
import { guid } from '@datorama/akita';
import { OnboardingManageService } from 'app/onboarding/services';
import { LoggerService } from '@services';
import {
    createPlaceholderDocument,
    DocumentsQuery,
    DocumentsStore,
    FailedDocumentsQuery,
    FailedDocumentsStore,
    FailedPurchasesStore,
    FolderQuery,
} from '../store';
import { delay, distinctUntilChanged, expand, filter, finalize, mergeMap, retry, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, of, Subject, timer } from 'rxjs';
import { DocumentsSort, IDocument, mapDocumentsSort, SortType } from '../types';
import { FilesUploadedSnackbarComponent } from '@shared/components/files-uploaded-snackbar/files-uploaded-snackbar.component';
import { OrderType } from '@enums';
import { IFailedDocument, IResponseStatus } from '@core/types';

const API_POLLING_INTERVAL = 1000;

@Injectable()
export class DocumentsService {
    public resetLoading$ = new Subject<void>();
    public startPollingSignal$ = new BehaviorSubject<boolean>(false);
    public stopPollingSignal$ = new Subject<boolean>();
    public totalNumOfFilesInUploadQueue = new BehaviorSubject(0);
    public numOfCanceledFiles = new BehaviorSubject(0);
    public numOfFailedFiles = new BehaviorSubject(0);
    public sortType = new BehaviorSubject<SortType>(SortType.manual);
    public orderType = new BehaviorSubject<OrderType>(OrderType.asc);

    constructor(
        private readonly documentsStore: DocumentsStore,
        private readonly documentsQuery: DocumentsQuery,
        private readonly folderQuery: FolderQuery,
        private readonly failedDocumentsQuery: FailedDocumentsQuery,
        private readonly failedDocumentsStore: FailedDocumentsStore,
        private readonly failedPurchasesStore: FailedPurchasesStore,
        private readonly folderApi: FolderApi,
        private readonly documentApi: DocumentApi,
        private readonly log: LoggerService,
        private readonly snackBar: MatSnackBar,
        private readonly onboarding: OnboardingManageService,
    ) {
    }

    public startPolling(): void {
        this.startPollingSignal$.next(true);
    }

    public stopPolling(): void {
        this.stopPollingSignal$.next(true);
        this.startPollingSignal$.next(false);
    }

    public startOnboardingUploadFlow(documentsAmount: number, failedDocumentsAmount: number): void {
        (documentsAmount > failedDocumentsAmount && this.onboarding.isActive)
            ? this.onboarding.showMedalFirstUpload()
            : this.onboarding.giveAnotherTry();
    }

    public folderStatusPolling(): Observable<IResponseStatus<IDocument>> {
        return this.startPollingSignal$
            .pipe(
                distinctUntilChanged(),
                tap((value) => {
                    this.log.info('getPolling: resumePolling', value);
                }),
                filter((value) => value),
                mergeMap(() => this.pollDocumentsUntil()),
            );
    }

    public setLoadingState(state: boolean): void {
        this.log.info('setLoadingState', state);
        this.documentsStore.setLoading(state);
    }

    public getLoadedDocuments(): Observable<IResponseStatus<IDocument>> {
        return this.getFolder()
            .pipe(
                tap((folder: IResponseStatus<IDocument>) => {
                    const documents = this.normalizeExistingDocuments(folder.documents);

                    if (documents.length) {
                        this.sortType.next(folder.sortedBy as SortType);
                        this.orderType.next(folder.sortingOrder);
                        this.documentsStore.set(documents);
                        this.processDocuments(folder);
                    } else {
                        this.documentsStore.set([]);
                    }
                }),
            );
    }

    public refreshDocuments(): Observable<IResponseStatus<IDocument>> {
        const loadedDocuments$ = this.getLoadedDocuments();

        return loadedDocuments$.pipe(
            expand((folder) => {
                return this.allDocumentsProcessed(folder)
                    ? EMPTY
                    : loadedDocuments$.pipe(delay(400));
            }),
        );
    }

    public removeDocument(documentId: string): Observable<void> {
        const folderId = this.folderQuery.getValue().id;
        this.documentsStore.setLoading(true);

        return this.documentApi.removeDocument(folderId, documentId)
            .pipe(
                tap(() => {
                    this.documentsStore.remove(documentId);
                    this.documentsStore.setLoading(false);
                }),
            );
    }

    public removeFailedDocument(documentId: string): Observable<void> {
        const folderId = this.folderQuery.getValue().id;

        return this.documentApi.removeDocument(folderId, documentId)
            .pipe(
                tap(() => this.failedDocumentsStore.remove(documentId)),
            );
    }

    public removeFailedDocumentFromStore(documentId: string): void {
        this.failedDocumentsStore.remove(documentId);
    }

    public anUploadFailed(file: any, error: any): void {
        this.log.info('anUploadFailed', file);
        let errorMessage = `Uploading ${file.fileAsObject.name} failed. Please try to upload the file again.`;

        if (error.status === 413) {
            errorMessage = `${file.fileAsObject.name} is too large to be a valid document.`;
        }

        this.removeFailedFileAfterUpload({
            id: file.uuid,
            fileName: file.fileAsObject.name,
            message: errorMessage,
        });
    }

    public showUploadedSnackbar(): void {
        const filesAmount = this.documentsQuery.getCount((entity) => entity.isProcessed);

        this.snackBar.openFromComponent(FilesUploadedSnackbarComponent,
            {
                data: {
                    filesAmount,
                    messageForOneFile: '1 title document',
                    messageForManyFiles: `${filesAmount} title documents`,
                },
                panelClass: 'files-uploaded-snackbar',
                duration: 6000,
            },
        );
    }

    public pollingDocumentsHasFailed(): void {
        this.log.info('documentsQuery', this.documentsQuery.getAll());
        this.log.info('failedDocumentsQuery', this.failedDocumentsQuery.getAll());
        this.log.info('Total failed', this.documentsQuery.getCount(), this.failedDocumentsQuery.getCount());
    }

    public fillWithEmptyFiles(count: number): void {
        const currentFilesCount = this.documentsQuery.getCount();

        for (let index = currentFilesCount; index < count + currentFilesCount; index++) {
            this.documentsStore.add(createPlaceholderDocument(guid()), { loading: true });
        }
    }

    public resetLoading(): void {
        this.stopPolling();
        this.resetLoading$.next();
    }

    public clearFailedPurchases(): void {
        this.failedPurchasesStore.reset();
    }

    public updateFailedPurchases(purchasedFiles: any[], transactionId: string): void {
        for (const pFile of purchasedFiles) {
            if (pFile.isError) {
                this.failedPurchasesStore.add({
                    id: pFile.id,
                    txId: transactionId,
                    folderId: pFile.folderId,
                    documentId: pFile.documentId,
                    kind: pFile.kind,
                    message: pFile.message,
                    reference: pFile.reference || pFile.titleNumber,
                    purchasedAt: pFile.purchasedAt,
                    cost: pFile.cost,
                });
            }
        }
    }

    public removeFailedPurchaseAlert(documentId: string): void {
        this.failedPurchasesStore.remove(documentId);
    }

    public updateSort(sort: DocumentsSort): void {
        const folderId = this.folderQuery.getValue().id;
        const mappedSort = mapDocumentsSort(sort);

        this.documentApi.sort(folderId, mappedSort).subscribe();
    }

    private doStartPolling(): Observable<IResponseStatus<IDocument>> {
        this.log.info('doStartPolling');

        return timer(API_POLLING_INTERVAL * 3, API_POLLING_INTERVAL * 3)
            .pipe(
                throttleTime(API_POLLING_INTERVAL * 2),
                switchMap(() => this.getFolder()),
                tap((folder: IResponseStatus<IDocument>) => {
                    this.log.info('startPollingInternal: tap', folder);

                    const isAllDocumentsProcessed = folder.documents.every((document) => document.isProcessed);
                    const failedDocuments = folder.documents.filter((document) => document.isError).length;
                    const isFilled = !folder.documents.length;

                    this.processDocuments(folder);

                    const nTotal = this.totalNumOfFilesInUploadQueue.getValue();
                    const nCanceled = this.numOfCanceledFiles.getValue();
                    const nFailed = this.numOfFailedFiles.getValue();

                    const allDone = (nTotal - nCanceled - nFailed) <= folder.documents.length;

                    if (isFilled || isAllDocumentsProcessed) {
                        if (allDone) {
                            this.showUploadedSnackbar();
                            this.clearPlaceholders();
                            this.setLoadingState(false);
                            this.stopPolling();

                            this.startOnboardingUploadFlow(folder.documents.length, failedDocuments);
                        }
                    }
                }),
                retry(3),
            );
    }

    private pollDocumentsUntil(): Observable<IResponseStatus<IDocument>> {
        return this.doStartPolling()
            .pipe(
                takeUntil(
                    // stop polling on either button click or change of categories
                    this.stopPollingSignal$,
                ),
                // for demo purposes only
                finalize(() => this.log.info('pollDocumentsUntil: Polling stopped')),
            );
    }

    private allDocumentsProcessed(folder: IResponseStatus<IDocument>): boolean {
        return folder.documents.every((document) => document.isProcessed);
    }

    private getFolder(): Observable<IResponseStatus<IDocument> | null> {
        const folderId = this.folderQuery.getValue().id;

        if (folderId) {
            this.log.info('this.folderApi.getFolder', folderId);

            return this.folderApi.getFolder(folderId);
        } else {
            this.log.info('getFolder --> setLoadingState', false);
            this.setLoadingState(false);
            this.stopPolling();

            return of();
        }
    }

    private normalizeFailedDocument(document: IDocument): IFailedDocument {
        return {
            id: document.id,
            fileName: document.fileName,
            message: document.messages[0].message,
        };
    }

    private normalizeDocument(document: IDocument): IDocument {
        return {
            id: document.id,
            fileName: document.fileName,
            dataPoints: document.dataPoints,
            address: this.getPoint(document.dataPoints, 'address'),
            issueDate: new Date(this.getPoint(document.dataPoints, 'issue_date')),
            titleNumber: this.getPoint(document.dataPoints, 'title_number'),
            ownership: this.getPoint(document.dataPoints, 'ownership'),
            type: document.type || 'pdf',
            isProcessed: document.isProcessed,
            source: document.source,
            messages: document.messages,
            isError: document.isError,
            isAccepted: document.isAccepted,
        };
    }

    private processDocuments(folder: IResponseStatus<IDocument>): void {
        folder.documents.forEach((document) => {
            const isDocumentPresent = this.documentsQuery.hasEntity(document.id);
            const isFailedDocumentPresent = this.failedDocumentsQuery.hasEntity(document.id);

            if (document.isProcessed && !isDocumentPresent && !isFailedDocumentPresent) {
                if (!document.isError) {
                    const data = this.normalizeDocument(document);
                    this.updateDocumentWithNewFileData(data);
                }

                if (document.isError && document.messages && document.messages.length) {
                    const data = this.normalizeFailedDocument(document);
                    this.removeFailedFileAfterUpload(data);
                }
            }
        });
    }

    private updateDocumentWithNewFileData(document: IDocument): void {
        this.documentsStore.add(document, { loading: true, prepend: true });
        this.removeOnePlaceholder();
    }

    private removeOnePlaceholder(): void {
        this.documentsQuery.hasEntity((entity: IDocument) => {
            if (!entity.isProcessed) {
                this.documentsStore.remove(entity.id);
                return true;
            }
        });
    }

    private clearPlaceholders(): void {
        this.documentsStore.remove((document: IDocument) => document.isProcessed === false);
    }

    private removeFailedFileAfterUpload(document: IFailedDocument): void {
        this.failedDocumentsStore.add(document);
        this.removeOnePlaceholder();
    }

    private getPoint(dataPoints: { [key: string]: string }, name: string): string {
        return dataPoints[name] || '';
    }

    private normalizeExistingDocuments(documents: IDocument[]): IDocument[] {
        const result: IDocument[] = [];

        documents.map((item: any) => {
            if (!item.isError && item.dataPoints) {
                result.push(this.normalizeDocument(item));
            }
        });

        return result;
    }
}
