import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { LandRegistryApi } from '../api';

import { IFilesForDownload, IPurchasedFile } from '../types';
import { PurchasedFilesStore, PurchasedSelectedFilesStore } from '../store';
import { HttpQueryParamsService } from '@services';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICollectionPageEvent, ICollectionTableUI } from '@core/types';

@Injectable()
export class PurchaseService {

    constructor(
        private readonly landRegistryApi: LandRegistryApi,
        private readonly queryParamsService: HttpQueryParamsService,
        private readonly purchasedFilesStore: PurchasedFilesStore,
        private readonly selectedFilesStore: PurchasedSelectedFilesStore,
    ) {
    }

    public getPurchasedFilesList(uiState: ICollectionTableUI): Observable<IPurchasedFile[]> {
        this.purchasedFilesStore.setLoading(true);
        const params = this.queryParamsService.buildCollectionParams(uiState);

        return this.landRegistryApi.getPurchasedFilesList(params).pipe(
            map((response) => this.getCollectionFrom(response)),
            catchError((error) => {
                this.purchasedFilesStore.updatePurchasedFiles([], { totalCount: 0 });
                this.purchasedFilesStore.setLoading(false);

                return of(error);
            }),
        );
    }

    public downloadPurchasedFiles(files: IFilesForDownload[]): Observable<HttpResponse<any>> {
        return this.landRegistryApi.downloadPurchasedFiles(files);
    }

    public selectFiles(selection: IPurchasedFile[]): void {
        const files = this.convertFilesForDownload(selection);
        this.selectedFilesStore.set(files);
    }

    public resetSelectedFiles(): void {
        this.selectedFilesStore.reset();
    }

    public updateSearchUI(search: string): void {
        this.purchasedFilesStore.update((state) => ({ ui: { ...state.ui, search } }));
    }

    public updatePaginationUI(page: ICollectionPageEvent): void {
        this.purchasedFilesStore.update((state) => ({ ui: { ...state.ui, page } }));
    }

    public resetState(): void {
        this.purchasedFilesStore.reset();
    }

    private getCollectionFrom(response: HttpResponse<IPurchasedFile[]>): IPurchasedFile[] {
        const totalCount = +response.headers.get('X-Total-Count');
        this.purchasedFilesStore.updatePurchasedFiles(response.body, { totalCount });

        return response.body;
    }

    private convertFilesForDownload(files: IPurchasedFile[]): IFilesForDownload[] {
        return files.map((file) => {
            const { id, folderId, documentId } = file;

            return {
                id,
                folderId,
                documentId,
            };
        });
    }
}
